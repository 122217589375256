import React from 'react'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Query } from 'react-apollo'
import { CustomerFramework, CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'
import { Center } from '~/components/Core/Layout/Center'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props {
    children: (topics: LawArticleTopic[]) => React.ReactNode
    articleId: number
    abstractLawArticleId?: number
}

interface State {}

const GET_LAW_ARTICLE_TOPICS_QUERY = gql`
    query lawArticle($articleId: Int!, $customerSlug: String, $filters: TopicFilters, $departmentId: Int) {
        lawArticle(id: $articleId) {
            id
            topics(filters: $filters, customerSlug: $customerSlug) {
                id
                name
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

interface LawArticle {
    lawArticle: {
        id: number
        topics: LawArticleTopic[]
    }
}

export interface LawArticleTopic {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
}

export class LawArticleTopicsQuery extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, articleId, abstractLawArticleId } = this.props

        return (
            <Query<LawArticle>
                query={GET_LAW_ARTICLE_TOPICS_QUERY}
                variables={{
                    articleId,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                    filters: {
                        forAbstractLawArticleId: abstractLawArticleId,
                        customerFrameworkIds: this.context.activeProfiles,
                        onlyNonArchief: true,
                    },
                }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Center>
                                <Spinner large={true} delayed={true} />
                            </Center>
                        )
                    }

                    if (!data || !data.lawArticle || !data.lawArticle.topics.length) {
                        return null
                    }

                    return children(data.lawArticle.topics)
                }}
            </Query>
        )
    }
}
